import { writable } from "svelte/store";
import { fetchZipCodes } from "./fetchZipCodes";
const initialState = [];
export const getZipStore = (sheetConfig) => {
    const store = writable(initialState);
    const load = async () => {
        try {
            const res = await fetchZipCodes(sheetConfig);
            store.set(res);
        }
        catch (e) {
            console.error("Cannot load zips", e);
        }
    };
    return { store, load };
};
