export const storedZipDataItemKeys = [
    "stateShort",
    "zip",
    "availability",
    "servingNow",
];
export const StoredZipCSVColumnNames = [
    "state_id",
    "zip",
    "Availability",
    "serving_now",
];
