// exchanging data with initialized HS form is hard, creating some window vars here
const hsFormWindowState = (key) => ({
    update: (data) => {
        try {
            window[key] = Object.assign(Object.assign({}, window[key]), data);
        }
        catch (e) { }
    },
    get: () => {
        try {
            return window[key];
        }
        catch (_a) {
            return {};
        }
    },
});
export const hsFormStateBooking = hsFormWindowState("hsFormStateBooking");
export const hsFormStateNewsletter = hsFormWindowState("hsFormStateNewsletter");
