import { storedZipDataItemKeys, StoredZipCSVColumnNames, } from "./types";
const PAGE_SIZE = 500;
function validColumnNames(header) {
    return StoredZipCSVColumnNames.reduce((containsAll, currentKey) => containsAll && header.includes(currentKey), true);
}
function isValidStoredZipDataItem(item) {
    const itemKeys = Object.keys(item);
    return storedZipDataItemKeys.reduce((valid, key) => valid && itemKeys.includes(key), true);
}
const colNameLookup = {
    state_id: "stateShort",
    zip: "zip",
    Availability: "availability",
    serving_now: "servingNow",
};
const unmarshal = (csv) => {
    const rows = csv.split("\n");
    if (rows.length < 1) {
        return;
    }
    const [header, ...rest] = rows;
    const columns = header.split(",");
    if (validColumnNames(columns)) {
        return rest.map((row) => {
            const currentRow = {};
            const splitRow = row.split(",");
            columns.forEach((col, index) => {
                const zipDataItemKey = colNameLookup[col];
                if (zipDataItemKey !== undefined) {
                    currentRow[zipDataItemKey] = `${splitRow[index]}`;
                }
            });
            if (isValidStoredZipDataItem(currentRow)) {
                return currentRow;
            }
            else {
                console.warn(`Found invalid deregulated row: ${currentRow}`);
            }
        }, []);
    }
    else {
        throw new Error(`Missing required deregulated zip column names: ${columns}`);
    }
};
export const fetchZipCodes = async (config) => {
    const zipCSV = await fetch(config.zipsCsvUrl);
    const csvText = await (await zipCSV.blob()).text();
    return unmarshal(csvText);
};
