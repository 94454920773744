import { modifyFormOnLoad } from "./location-input/hubspot/hsFormUtils";
import { hsFormStateBooking, hsFormStateNewsletter } from "./windowVars";
const getFormFromCb = (cbArg) => {
    if ("0" in cbArg && "length" in cbArg && cbArg.length === 1) {
        return cbArg["0"];
    }
    return cbArg;
};
let submittedSuccessEmail = undefined;
export const initializeHubspotForms = ({ hsFormSuccess, hsFormNewsletter, }) => {
    // initialize success hs
    window.hbspt.forms.create(Object.assign(Object.assign({}, hsFormSuccess), { onFormReady: (args) => {
            const form = getFormFromCb(args);
            window.hsFormPreorder = form;
            modifyFormOnLoad(form);
        }, onFormSubmit: (args) => {
            var _a;
            const form = getFormFromCb(args);
            const submittedEmail = form.querySelector('input[name="email"]').value;
            submittedSuccessEmail = submittedEmail;
            (_a = hsFormSuccess.onFormSubmit) === null || _a === void 0 ? void 0 : _a.call(hsFormSuccess, form);
        }, onFormSubmitted: (form, args) => {
            var _a;
            (_a = hsFormSuccess.onFormSubmitted) === null || _a === void 0 ? void 0 : _a.call(hsFormSuccess, form, Object.assign(Object.assign({}, args), { submissionValues: Object.assign(Object.assign({}, args.submissionValues), hsFormStateBooking.get()) }));
        } }));
    // newsletter hs
    window.hbspt.forms.create(Object.assign(Object.assign({}, hsFormNewsletter), { onFormReady: (args) => {
            const form = getFormFromCb(args);
            window.hsFormNewsletter = form;
            modifyFormOnLoad(form);
        }, onFormSubmitted: (form, args) => {
            /**
             * redirect to the payment page
             */
            var _a;
            (_a = hsFormNewsletter.onFormSubmitted) === null || _a === void 0 ? void 0 : _a.call(hsFormNewsletter, form, Object.assign(Object.assign({}, args), { submissionValues: Object.assign(Object.assign({}, args.submissionValues), hsFormStateNewsletter.get()) }));
        } }));
};
